import React from 'react';

const Success = () => (
  <div>
    <h2>Success</h2>
    <p>You are authenticated</p>
  </div>
);

export default Success;
